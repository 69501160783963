import React, { useEffect } from 'react';
import { GlobalStyles } from './styles/global';
import { Header } from './components/Header/Header';


export const App = () => {
  return (
    <div className="App">
      <GlobalStyles />
      <Header />
    </div>
  );
};

