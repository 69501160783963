import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #27211f;
  color: #fff;
  h1 {
    font-size: 3.5rem;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: 300;
  }
`;
