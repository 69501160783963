import React from 'react';
import * as S from './styles';

export const Header = () => {
  return (
    <S.Container>
      <h1>Service Desk Bot</h1>
      <h3>Lojas Riachuelo</h3>
    </S.Container>
  );
};
